







































import ImportFileList from '@/components/ImportFileList.vue';
import Upload from '@/components/Upload.vue';
import { Component, Mixins, Vue } from 'vue-property-decorator';
import RoutingMixin from '@/mixins/RoutingMixin';

@Component({
  metaInfo(this: ViewLogin) {
    return {
      title: 'Se connecter',
      titleTemplate: `%s – ${process.env.VUE_APP_NAME}`,
    };
  },
})
export default class ViewLogin extends Mixins(RoutingMixin) {
  async login(): Promise<void> {
    global.location.href = this.generateApiRoute('/oauth2/google/start', { redirect: global.location.href });
  }
}
